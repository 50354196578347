<h4 mat-dialog-title>New Alert</h4>
<div mat-dialog-content class="scrollable-list">
  <mat-list >

      <mat-card *ngFor="let dt of data" class="mb-3">

        <mat-card-header>
          <mat-card-title> <h6>Alert Number: {{ dt.id }}</h6> </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h6>  <span color="priamry">Message: </span>{{ dt.message }}</h6>
        </mat-card-content>
          
        <mat-card-footer>
          <div class="row my-2">
            <div class="col-4" *ngFor="let camera of dt.cameras">
              <button mat-raised-button  color="primary" (click)="opencamera(camera.url)">
                <!-- //(click)="startCamera(camera)" -->
                <mat-icon>camera</mat-icon>
                {{camera.name}}
              </button>
            </div>
          </div>
        </mat-card-footer>

      </mat-card>

  </mat-list>


</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
</div>