
<div class="container mt-4">
 
    <div class="row mb-3">
      <h4 class="col-6 text-start">Notifications List</h4>
   
    </div>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
       
      <ng-container matColumnDef="ID">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id }} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="Capture">
        <th mat-header-cell *matHeaderCellDef> Capture </th>
        <td mat-cell *matCellDef="let element"> {{element.sensor_name}} </td>
      </ng-container> -->

      <ng-container matColumnDef="Message">
        <th mat-header-cell *matHeaderCellDef> Message </th>
        <td mat-cell *matCellDef="let element"> {{element.message }} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.alert_type}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Value">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element"> {{element!.value }} </td>
      </ng-container>

      <ng-container matColumnDef="Threshold">
        <th mat-header-cell *matHeaderCellDef> Threshold </th>
        <td mat-cell *matCellDef="let element"> {{element!.threshold}} </td>
      </ng-container>

      <ng-container matColumnDef="State">
        <th mat-header-cell *matHeaderCellDef> State </th>
        <td mat-cell *matCellDef="let element"> {{element!.status}} </td>
      </ng-container>
    

      <!-- <ng-container matColumnDef="Cameras">
        <th mat-header-cell *matHeaderCellDef> Cameras </th>
        <td mat-cell *matCellDef="let element"> {{element.cameras?.length}} </td>
      </ng-container> -->

      <ng-container matColumnDef="CreatedAt">
        <th mat-header-cell *matHeaderCellDef> Created At </th>
        <td mat-cell *matCellDef="let element"> {{element!.created_at | date:'shortDate'}} </td>
      </ng-container>
  

       <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> 
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openDeleteDialog(element.id,'400ms','200ms')"  [disabled]="element.status != 'active'">
              <mat-icon>dnd_forwardslash</mat-icon>
              <span>Desactivate</span>
            </button>
            <!-- <button mat-menu-item disabled>
              <mat-icon>notifications_off</mat-icon>
              <span>Disable alerts</span>
            </button> -->
          </mat-menu>
        </td>
      </ng-container>

      <!-- Table Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of notification">
    </mat-paginator>
  </div>