<div class="container-spec position-relative bg-dark text-white">

  <app-header *ngIf="isConnected" [active]="active"></app-header>
  
  <div *ngIf="isConnected" class="bg-black position-fixed z-3 top-0 left-0 h-100 top_sidemenu">
    <app-sidemenu [dataMenu]="dataMenu" *ngIf="active"></app-sidemenu>
  </div> 

  <div class="top_app position-relative" *ngIf="isConnected">
    <router-outlet />
  </div>
  
  <div *ngIf="!isConnected">
    <router-outlet />
  </div>

</div>

