import { Component, Input } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { AuthService } from '../../services/auth/auth-service.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { AlertsService } from '../../services/alerts/alerts.service';
import { Subscription } from 'rxjs';
import { SidemenuService } from '../../services/sidemenu/sidemenu.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatDividerModule, MatSlideToggle,CommonModule,MatIconModule,MatMenuModule,MatBadgeModule,MatButtonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  @Input() active: boolean = false;
  public notificationCount: number = 0;
  private subscription!: Subscription;

  constructor(private sideMenuservice:SidemenuService,private authService:AuthService,private router:Router,private alertsService: AlertsService){

  }

  ngOnInit(): void {

    this.subscription = this.alertsService.alerts$.subscribe(alerts => {
      this.notificationCount = alerts.length;
    });

  }

  togglesidemenu(active:boolean){
    this.sideMenuservice.ToggleMenu(active);
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login'])
  }
  
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
