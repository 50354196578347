<div class="container mt-5">

    <div class="row" *ngIf="projects; else shimmer">
      <div class="col-4 my-3" *ngFor="let project of projects">
        <mat-card class="project-card">
          <mat-card-header class="card-header">
            <mat-card-title>{{ project.name }}</mat-card-title>
          </mat-card-header>
  
          <mat-card-content>
            <div class="details">
              <div class="detail-item">
                <mat-icon class="detail-icon">map</mat-icon>
                <p class="detail-text">Zones: {{ project.zonesCount }}</p>
              </div>
              <div class="detail-item">
                <mat-icon class="detail-icon">videocam</mat-icon>
                <p class="detail-text">Cameras: {{ project.camerasCount }}</p>
              </div>
              <div class="detail-item">
                <mat-icon class="detail-icon">sensors</mat-icon>
                <p class="detail-text">Sensors: {{ project.sensorsCount }}</p>
              </div>
            </div>
          </mat-card-content>
  
          <mat-card-actions class="arrow-container">
            <button mat-icon-button class="arrow-right" [routerLink]="['/dashboard/',project.id]" >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <ng-template #shimmer>
       <div class="row" >
        <div class="col-4 my-3" *ngFor="let n of [1,2,3,4,5,6,7,8]">
          <mat-card>
            <div class="row  mx-auto w-100 text-center py-4 ">
              <div class="col-12 my-2"><ngx-skeleton-loader count="1" appearance="custom-content" class="w-100 m-auto mx-3"></ngx-skeleton-loader></div>
              <div class="col-12 my-2"><ngx-skeleton-loader count="1" appearance="custom-content" class="w-100 m-auto mx-3"></ngx-skeleton-loader></div>
              <div class="col-12 my-2" ><ngx-skeleton-loader count="1" appearance="custom-content" class="w-100 m-auto mx-3"></ngx-skeleton-loader></div>
            </div>
          </mat-card>
        </div>
        </div>
    </ng-template>

</div>
  