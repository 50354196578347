import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ProjectService } from '../../services/project/project.service';
import { Project, ProjectStatus } from '../../types/Project';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-project',
  standalone: true,
  imports: [    
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule],
  templateUrl: './add-project.component.html',
  styleUrl: './add-project.component.scss'
})
export class AddProjectComponent {
 
  form: FormGroup;
  projectStatusOptions :any;
  iscreate:boolean = true;

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private _snackBar: MatSnackBar,
    private projectService:ProjectService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: Project }
  ) {
    this.form = this.formBuilder.group({
      id: ['', Validators.nullValidator],
      name: ['', Validators.required],
      status: [ProjectStatus.InProgress, Validators.required],
      hasAlerts: [true, Validators.required],
      hash: ['', Validators.nullValidator],
      alert: ['', Validators.nullValidator],
    });

    this.projectStatusOptions = Object.keys(ProjectStatus).map(key => ({
      value: ProjectStatus[key as keyof typeof ProjectStatus],
      label: key
    }));

  }

  ngOnInit(): void {

    if (this.data) {
      this.form.patchValue(this.data);
      this.iscreate = false;
    }
    
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        this.projectService.addProject(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    } else {
      if (this.form.valid) {
        this.projectService.updateProject(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    }
   
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
