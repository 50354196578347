import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, Input, SimpleChanges } from '@angular/core';
import { Project } from '../../types/Project';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatFabButton } from '@angular/material/button';
import { MatList, MatListItem, MatNavList } from '@angular/material/list';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelActionRow, MatExpansionPanelContent, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddCameraComponent } from '../../components/add-camera/add-camera.component';
import { AlertsService } from '../../services/alerts/alerts.service';
import { Subscription } from 'rxjs';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'app-sidemenu',
  standalone: true,
  imports: [MatBadgeModule, RouterModule,CommonModule,MatDivider,MatExpansionPanelContent,MatExpansionPanelActionRow,MatIcon,MatButton,MatList,MatListItem,MatAccordion,MatExpansionPanelHeader,MatExpansionPanelTitle,MatExpansionPanelDescription,MatExpansionPanel,MatFabButton,MatNavList],
  templateUrl: './sidemenu.component.html',
  styleUrl: './sidemenu.component.scss'
})
export class SidemenuComponent {

  @Input({ required: true }) dataMenu: any  | undefined;

  readonly dialog = inject(MatDialog);

  showFiller:boolean = false;
  projects:Project[]  | undefined;

  public alerts: any[] = [];
  public alertsCount: number = 0;
  private subscription!: Subscription;

  constructor(  private alertsService: AlertsService,private cdr: ChangeDetectorRef){}

  ngOnInit(): void {
    this.projects = this.dataMenu;

    this.subscription = this.alertsService.alerts$.subscribe(alerts => {
      this.alerts = alerts;
      this.alertsCount = alerts.length;
    });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataMenu']) {
      this.cdr.detectChanges();
      this.projects = this.dataMenu;
    }
  }

  openCreateCameraDialog(){
    this.dialog.open(AddCameraComponent, {
      width: '75vw',
    }) 
    
  }

}
