import { Component,ChangeDetectionStrategy,Inject,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../pipes/safe.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet, MatChipsModule } from '@angular/material/chips';
import {  MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { RecordingService } from '../../services/recording/recording.service';
import { CreateRecordingComponent } from '../../components/create-recording/create-recording.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCell, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SensorTypesService } from '../../services/sensor-types/sensor_types.service';
import { MatMenuModule } from '@angular/material/menu';
import { AddSensorTypeComponent } from '../../components/add-sensor-type/add-sensor-type.component';

@Component({
  selector: 'app-sensor-types',
  standalone: true,
  imports: [
    MatHeaderCell, MatRow,MatRowDef,MatIconModule, MatHeaderCellDef,MatHeaderRow,MatHeaderRowDef,MatCell,MatPaginatorModule, RouterLink,MatChip,MatTableModule
    ,MatChipsModule, MatChipSet, CommonModule, NgxSkeletonLoaderModule,
     MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,
     MatGridList,MatGridTile,MatButtonModule,SafePipe,MatMenuModule],
  templateUrl: './sensor-types.component.html',
  styleUrl: './sensor-types.component.scss'
})
export class SensorTypesComponent {

  displayedColumns: string[] = [
    'Name', 'Unit', 'Type', 'MinValue', 'MaxValue',  'AlertValue', 'CreatedAt', 'Action'
  ];
  dataSource :any
  sensorTypes :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private sensortypesService:SensorTypesService,private _snackBar: MatSnackBar){

  }
  ngOnInit() {
    setTimeout(() => {
      this.getSensortypes();
    }, 500);
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getSensortypes(){

    this.sensortypesService.getAllSensorTypes().subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
          this.dataSource = new MatTableDataSource(response);
        }
      }
    );
  }

  
  openCreateDialog(): void {
     this.dialog.open(AddSensorTypeComponent, {
      width: '75vw',
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getSensortypes();
      }
    });
  }

  openUpdateDialog(data:any): void {
    this.dialog.open(AddSensorTypeComponent, {
     width: '75vw',
     data:data
   }).afterClosed().subscribe((result) => {
     if(result){
       this.getSensortypes();
     }
   });
 }


  openDeleteDialog(sensorType_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteSensorType, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { sensorType_id: sensorType_id }
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getSensortypes();
      }
    });

  }
}

@Component({
  selector: 'delete-sensortype-dialog',
  templateUrl: '../../templates/delete-sensortype-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteSensorType {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteSensorType>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { sensorType_id: number }
  ,private sensorTypeService:SensorTypesService,private _snackBar: MatSnackBar){

  }

  deleteSensorType(sensorType_id:number){
    this.sensorTypeService.deleteSensorType(sensorType_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true); 
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
