import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SensorService } from '../../services/sensor/sensor.service';
import { Card } from '../../types/Card';
import { SensorTypesService } from '../../services/sensor-types/sensor_types.service';
import { CameraService } from '../../services/camera/camera.service';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-add-sensor',
  standalone: true,
  imports: [    
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule],
    templateUrl: './add-sensor.component.html',
    styleUrl: './add-sensor.component.scss'
  })
  export class AddSensorComponent {

  formcameras!: FormGroup;
  form: FormGroup;
  iscreate:boolean = true;
  sensorTypes: any;
  cameras: any=null;

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private _snackBar: MatSnackBar,
    private sensorService:SensorService,
    private sensortypesService:SensorTypesService,
    private cameraService:CameraService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddSensorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:   Card 
  ) {

    //this.sensorTypes = Object.values(SensorType);

    this.form = this.formBuilder.group({
      id: ['', Validators.nullValidator],
      name: ['', Validators.required],
      cardId: ['', Validators.required],
      sensor_type_id: ['', Validators.required],
      location_lng: [0, Validators.required],
      location_lat: [0, Validators.required],
      startedAt: ['', Validators.nullValidator],
      description: ['', Validators.nullValidator],
      status: ['', Validators.nullValidator],
    
    });

   

  }

  ngOnInit(): void {

    if (this.data.name) {
      this.form.patchValue(this.data);
      this.iscreate = false;
    }else{
      this.form.patchValue({'cardId':this.data.cardId});
    }
    

    this.getSensortypes();
    this.getcamerasofzone();


    
  }

  // Get the FormArray
  get optionsArray(): FormArray {
    return this.formcameras.get('camera_ids') as FormArray;
  }

  // Get selected camera IDs
  get selectedValues(): string[] {
    return this.optionsArray.controls
      .map((control, i) => control.value ? this.cameras[i].id : null)
      .filter((id: string | null) => id !== null) as string[];
  }

  getcamerasofzone() {
    this.cameraService.getAllCameras(this.data.zoneId).subscribe(
      (response) => {
        if (response?.length > 0) {
          this.cameras = response;

          // Initialize the form array with controls for each camera
          this.formcameras = this.formBuilder.group({
            camera_ids: this.formBuilder.array(
              this.cameras.map(() => this.formBuilder.control(false))
            )
          });
        }
      }
    );
  }


  getSensortypes(){

    this.sensortypesService.getAllSensorTypes().subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
        }
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        let data = {"sensor":this.form.value,   "camera_ids": this.selectedValues}
        this.sensorService.addSensor(data).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    } else {
      if (this.form.valid) {
        this.sensorService.updateSensor(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    }
   
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
