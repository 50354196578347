<nav class="navbar navbar-expand-lg navbar-dark bg-dark border-bottom position-fixed w-100 z-3" >
    <div class="container-fluid ">
  
      <button mat-mini-fab (click)="togglesidemenu(!active)" href="#menu" class="sidemenu_button"><mat-icon
        class="mat-18 text-white" fontIcon="menu"></mat-icon> </button>
  
      <div class="float-right">
        <button mat-icon-button class="mr-2">
          <mat-icon [matBadge]="notificationCount" class="badge_icon" matBadgeColor="warn" matBadgeSize="small"
            matBadgeOverlap="true" [matBadgeHidden]="notificationCount === 0">
            notifications
          </mat-icon>
        </button>
  
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
  
      <mat-menu  #menu="matMenu" class="px-2">
        <button mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>Settings</span>
        </button>
        <button mat-menu-item>
          <mat-icon>business</mat-icon>
          <span>Societe</span>
        </button>
        <button mat-menu-item>
          <mat-icon>light_mode</mat-icon>
          <span>LightMode</span>
        </button>
        <mat-divider> </mat-divider>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
  
      </mat-menu>
  
    </div>
  </nav>