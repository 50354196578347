<h1 mat-dialog-title  *ngIf="iscreate " >Add new Sensor</h1>
<h1  mat-dialog-title *ngIf="!iscreate "  >Update Sensor {{form.get('name')?.value}}</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Name </mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Unit </mat-label>
        <input matInput formControlName="unit">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Sensor Type </mat-label>
      <mat-select formControlName="type">
        <mat-option  [value]="type" *ngFor="let type of types">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Max Value </mat-label>
      <input matInput formControlName="max_value">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Min Value </mat-label>
      <input matInput formControlName="min_value">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Alert Value </mat-label>
        <input matInput formControlName="alert_value">
    </mat-form-field>


  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>