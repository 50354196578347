<h1 mat-dialog-title *ngIf="iscreate">Create New Recording</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Project</mat-label>
      <mat-select placeholder="" formControlName="id_project">
        <mat-option [value]="0">dd</mat-option>
        <mat-option [value]="1">sq</mat-option>
        <mat-option [value]="2">q</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Zone</mat-label>
      <mat-select placeholder="" formControlName="id_zone">
        <mat-option [value]="0">dd</mat-option>
        <mat-option [value]="1">sq</mat-option>
        <mat-option [value]="2">q</mat-option>
      </mat-select>
    </mat-form-field>
 
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Camera</mat-label>
      <mat-select placeholder="" formControlName="id_camera" >
        <mat-option [value]="0">dd</mat-option>
        <mat-option [value]="1">sq</mat-option>
        <mat-option [value]="2">q</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="datepicker" formControlName="date" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="datepicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Time From</mat-label>
      <input matInput [ngxTimepicker]="TimeFrom" [format]="24" formControlName="from"/>
      <ngx-material-timepicker-toggle [for]="TimeFrom"></ngx-material-timepicker-toggle>
      <ngx-material-timepicker #TimeFrom [theme]="darkTheme"></ngx-material-timepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100" >
      <mat-label>Time To</mat-label>
      <input matInput [ngxTimepicker]="TimeTo" [format]="24" formControlName="to"/>
      <ngx-material-timepicker-toggle [for]="TimeTo" ></ngx-material-timepicker-toggle>
      <ngx-material-timepicker #TimeTo [theme]="darkTheme"></ngx-material-timepicker>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>
