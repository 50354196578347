import { ChangeDetectionStrategy, Component,Inject,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../pipes/safe.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet, MatChipsModule } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ZonesService } from '../../services/zone/zones.service';
import { AddzoneComponent } from '../../components/add-zone/add-zone.component';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-zones',
  standalone: true,
  imports: [RouterLink,MatChip,MatChipsModule, MatChipSet,MatIcon, CommonModule, NgxSkeletonLoaderModule, MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,MatGridList,MatGridTile,MatButtonModule,SafePipe],
  templateUrl: './zones.component.html',
  styleUrl: './zones.component.scss'
})
export class ZonesComponent {

  projectName:string | null = "";
  projectId: any;
  breakpoint:number = 4;
  zones: any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private zonesService:ZonesService,private _snackBar: MatSnackBar,private route: ActivatedRoute){

  }

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('project_id'); 
      this.projectName = params.get('project_name'); 

      setTimeout(() => {
        this.getZones();
      }, 500);

    });

    this.breakpoint = (window.innerWidth <= 768) ? 1 : 4;

    
  }

  onResize(event:any) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 4;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getZones() {
    
    this.zonesService.getAllZones(this.projectId).subscribe(
      (response) => {

        if (response) {
          this.zones = response;
        }else{
          this.zones = [];
        }
      }
    );
  }
  
  openCreateDialog(): void {
     this.dialog.open(AddzoneComponent, {
      width: '75vw',
      data : {projectId:this.projectId}
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getZones();
      }
    });
  }

  openUpdateDialog(zone:any): void {
    
    this.dialog.open(AddzoneComponent, {
     width: '75vw',
     data:zone,
   }).afterClosed().subscribe((result) => {
     if(result){
       this.getZones();
     }
   });
 }


  openDeleteDialog(zone_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteZone, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { zone_id: zone_id }
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getZones();
      }
    });

  }
}

@Component({
  selector: 'delete-zone-dialog',
  templateUrl: '../../templates/delete-zone-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteZone {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteZone>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { zone_id: number }
  ,private zoneService:ZonesService,private _snackBar: MatSnackBar){

  }

  deleteZone(zone_id:number){
    this.zoneService.deleteZone(zone_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true); 
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}