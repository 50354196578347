<h1 mat-dialog-title  *ngIf="iscreate " >Add new project</h1>
<h1  mat-dialog-title *ngIf="!iscreate "  >Update Project {{form.get('name')?.value}}</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Project name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option  *ngFor="let state of projectStatusOptions" [value]="state.value">{{state.value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Has alert</mat-label>
      <mat-select formControlName="hasAlerts">
        <mat-option selected [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>