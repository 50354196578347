import { ChangeDetectorRef, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { ZonesService } from '../../../services/zone/zones.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CardService } from '../../../services/card/card.service';
import { SensorService } from '../../../services/sensor/sensor.service';
import { forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, FormsModule, MatButtonToggleModule,RouterModule, MatIconModule, HighchartsChartModule, MatCard, MatCardContent, MatCardActions, MatCardFooter, MatCardImage, MatGridList, MatGridTile, MatButtonModule, CommonModule],
  templateUrl: './dashboard-details.component.html',
  styleUrl: './dashboard-details.component.scss',
})

export class DashboardDetailsComponent {


  // Highcharts: typeof Highcharts = Highcharts;
  // chartOptions: Highcharts.Options = {
  //   title: {
  //     text: 'ZONE A'
  // },
  // subtitle: {
  //     text: 'Projet 1'
  // },
  //   series: [{
  //     name:"capture 1 ",
  //     data: [1, 2, 3],
  //     type: 'line'
  //   },{
  //     name:"capture 2 ",
  //     data: [30, 12, 4, 1,2, 3],
  //     type: 'line'
  //   }]
  // };

  zones: any[] = [];  // Holds zone data
  projectId: any = null;

  currentFilter: string = 'daily'; // Default filter

  Highcharts: typeof Highcharts = Highcharts;
  chartOptionsList: Highcharts.Options[] = [];
  isActive:boolean = false;


  constructor(private zonesService: ZonesService, private route: ActivatedRoute, private cardservice: CardService, private sensorservice: SensorService) {
    HighchartsAccessibility(Highcharts);
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('project_id');

      setTimeout(() => {
        this.getZones();
      }, 500);

      setTimeout(() => {
        this.isActive = true;
      }, 3000);

    });

  }

  goToSensorDetails(data:any) {
    
  }

  getZones() {
    this.zonesService.getAllZones(this.projectId).pipe(
      switchMap((zones) => {
        zones = zones ? zones : []; // Ensure zones is an array

        // Create an array of observables that fetch cards and sensors for each zone
        const zoneObservables = zones.map((zone: { id: any, sensors: any }) => {
          return this.cardservice.getAllCards(zone.id).pipe(
            switchMap((cards) => {
              cards = Array.isArray(cards) ? cards : []; // Ensure cards is an array

              if (!cards || cards.length === 0) {
                // If no cards, set empty sensors for the zone
                zone.sensors = [];
                return [zone];
              }

              // Fetch sensors for each card
              const cardObservables = cards.map((card: { id: any, sensors: any }) => {
                return this.sensorservice.getAllSensors(card.id).pipe(
                  switchMap((sensors) => {
                    sensors = Array.isArray(sensors) ? sensors : []; // Ensure sensors is an array

                    if (!sensors || sensors.length === 0) {
                      // If no sensors, we don't need to fetch data
                      return [zone];
                    }

                    // Fetch data for each sensor
                    const sensorObservables = sensors.map((sensor: { id: any, data: any }) => {
                      return this.sensorservice.getAllData(sensor.id).pipe(
                        map((sensor_data) => {
                          sensor.data = sensor_data ? sensor_data : []; // Ensure sensor data is an array
                          return sensor; // Return sensor with its data
                        })
                      );
                    });

                    // Wait until all sensor data is fetched
                    return forkJoin(sensorObservables).pipe(
                      map((sensorsWithData) => {
                        zone.sensors = sensorsWithData; // Assign sensors with data to zone
                        return zone;
                      })
                    );
                  })
                );
              });

              // Wait until all card sensor observables are complete
              return forkJoin(cardObservables).pipe(
                map(() => zone) // Return the zone with its updated sensors
              );
            })
          );
        });

        // Wait until all zones are processed
        return forkJoin(zoneObservables);
      })
    ).subscribe((processedZones: any) => {
      this.zones = processedZones.length ? processedZones : [];
      this.updateCharts(this.zones) // Now set the zones with their sensors or empty array if no zones
    });
  }

  updateCharts(data: any[] = this.zones) {
    this.chartOptionsList = data.map((zone: any) => {
      const sensorSeries = Array.isArray(zone.sensors)
        ? zone.sensors.map((sensor: any) => {
          // Transform sensor data into [timestamp, value] pairs
          const sensorData = Array.isArray(sensor.data)
            ? sensor.data.map((entry: any) => [
              new Date(entry.insertedAt).getTime(), // Convert insertedAt to a timestamp
              entry.value,  // Sensor value
            ])
            : [];

          const filteredData = Array.isArray(sensorData) ? this.applyFilter(sensorData) : [];

          return {
            id: sensor.id,
            name: sensor.name,
            data: filteredData,  // Use the transformed data
            type: 'line' as Highcharts.SeriesLineOptions['type'],
          };
        })
        : [];

      return {
        title: { text: `Zone: ${zone.name}` },
        subtitle: { text: `Sensors in Zone: ${zone.name}` },
        series: sensorSeries,
        accessibility: { enabled: false },  // Disable accessibility warnings
        xAxis: {
          type: 'datetime',  // Ensure the X-axis uses time-based values
          title: { text: 'Time' }
        },
        yAxis: {
          title: { text: 'Sensor Value' }
        },
        plotOptions: {
          series: {
              pointStart: Date.UTC(2024, 1, 9),
              pointInterval: 24 * 3600 * 1000 // one day
          }
        },
      };
    });
  }

  applyFilter(data: [number, number][]) {

    const oneHour = 60 * 60 * 1000; // One hour in milliseconds
    const oneDay = 24 * oneHour; // One day in milliseconds
    const oneWeek = 7 * oneDay; // One week in milliseconds
    const oneMonth = 4 * oneWeek; // One week in milliseconds
    const oneYear = 12 * oneMonth; // One week in milliseconds
    const now = Date.now();

    switch (this.currentFilter) {

      case 'lasthour':
        return data.filter(([timestamp]: [number, any]) => {//last hour
          return now - timestamp <= oneHour ; 
        });

      case 'hourly':
        return data.filter(([timestamp]: [number, any]) => {//last 24 hours
          return now - timestamp <= oneHour * 24; 
        });

      case 'daily':
        return data.filter(([timestamp]: [number, number]) => {//last 30 days
          return now - timestamp <= oneDay * 30 ;
        });

      case 'weekly':
        return data.filter(([timestamp]: [number, number]) => { //last 4 weeks
          return now - timestamp <= oneWeek * 4  ;
        });

      case 'monthly':
        return data.filter(([timestamp]: [number, number]) => { //last 12 month
          return now - timestamp <= oneYear ;
        });

      case 'yearly':
        return data.filter(([timestamp]: [number, number]) => { //last 5 years
          return now - timestamp <= oneYear * 5 ;
        });

      default:
        return data;

    }
  }


}
