<mat-grid-list *ngIf="cameras; else shimmerGrid" [cols]="breakpoint" [rowHeight]="'45vh'" [gutterSize]="'15px'" class="mx-2 text-white" (window:resize)="onResize($event)">
  <mat-grid-tile *ngFor="let camera of cameras" [colspan]="1" [rowspan]="1">
    <mat-card>
      <mat-card-content class="mb-2">
        <div class="wrap">
          <img class="frame" [src]="camera.thumbnailUrl | safe" frameborder="0" scrolling="no"> 
        </div>
      </mat-card-content>
      <mat-card-footer class="text-center">
        <b>{{ camera.name }}</b>
      </mat-card-footer>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<ng-template #shimmerGrid>
  <mat-grid-list [cols]="breakpoint" [rowHeight]="'45vh'" [gutterSize]="'15px'" class="mx-2 text-white">
    <mat-grid-tile *ngFor="let i of [1, 2, 3, 4,1, 2, 3, 4]" [colspan]="1" [rowspan]="1">
      <mat-card>
        <mat-card-content class="mb-2">
          <div class="wrap">
            <ngx-skeleton-loader appearance="custom-content"  [theme]="{ 'height': '100%', 'width': '100%' }"></ngx-skeleton-loader>
          </div>
        </mat-card-content>
        <mat-card-footer class="text-center">
          <ngx-skeleton-loader appearance="custom-content"  [theme]="{ 'height': '20px', 'width': '60%', 'margin': '0 auto' }"></ngx-skeleton-loader>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</ng-template>
