<mat-list class="sidemenu scroll-bar" >
    <mat-accordion>
        <mat-expansion-panel *ngFor="let project of projects" class="mb-2">
          <mat-expansion-panel-header>
            <mat-panel-title> {{project.name}}</mat-panel-title>
          </mat-expansion-panel-header>
        
                <mat-list-item class="whitespace-nowrap p-0 ml-0">
                    <a mat-fab [routerLink]="['/zones',project.id,project.name]" routerLinkActive="router-link-active"  class="text-decoration-none text-white"> 
                        <span class=" left-5 ">Tous les zones</span>
                    </a>
                </mat-list-item>
            <mat-accordion>
                
                <mat-expansion-panel *ngFor="let zone of project.zones" class="mb-2">
                  <mat-expansion-panel-header class="p-1">
                    <mat-panel-title> {{zone.name}}</mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <mat-list class="p-0 "  dense>
                        <mat-list-item class="whitespace-nowrap p-0">
                            <a mat-fab [routerLink]="['/cameras',zone.id,zone.name]" routerLinkActive="router-link-active"  class="text-decoration-none text-white"> 
                                <mat-icon class="mat-18 position-absolute top-25">cameras</mat-icon>
                                <span class=" left-25 ">Cameras</span>
                            </a>
                        </mat-list-item>
                        <mat-list-item class="whitespace-nowrap p-0">
                            <a mat-fab [routerLink]="['/cards',zone.id,zone.name]" class="text-decoration-none text-white">
                                <mat-icon class="mat-18 position-absolute top-25">settings_input_component</mat-icon>
                                <span class=" left-25 ">Cards</span>
                            </a>
                        </mat-list-item>
                    </mat-list>
                  </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
            
          
        </mat-expansion-panel>
    </mat-accordion>

    <mat-divider> </mat-divider>

    <mat-list-item class="item_w_icon_content">
        <a [routerLink]="['/']" class="text-decoration-none text-white">
            <mat-icon class="mat-18 item_w_icon" fontIcon="dashboard"></mat-icon> Dashboard
        </a>
    </mat-list-item>

    <mat-list-item class="item_w_icon_content">
        <a [routerLink]="['/projects']" class="text-decoration-none text-white">
            <mat-icon class="mat-18 item_w_icon" fontIcon="domain"></mat-icon> All Projects
        </a>
    </mat-list-item>

    <mat-list-item class="item_w_icon_content">
        <a (click)="openCreateCameraDialog()"  class="text-decoration-none text-white cursor-pointer">
            <mat-icon class="mat-18 item_w_icon" fontIcon="add"></mat-icon> New Camera
        </a>
    </mat-list-item>

    <mat-list-item class="item_w_icon_content">
        <a [routerLink]="['/recordings']" class="text-decoration-none text-white">
            <mat-icon class="mat-18 item_w_icon" fontIcon="video_library"></mat-icon> Recordings
        </a>
    </mat-list-item>

    <mat-list-item class="item_w_icon_content">
        <a [routerLink]="['/sensortypes']" class="text-decoration-none text-white">
            <mat-icon class="mat-18 item_w_icon" fontIcon="sensors"></mat-icon> Sensor Types
        </a>
    </mat-list-item>

    <mat-list-item class="item_w_icon_content">
        <a [routerLink]="['/notifications']" class="text-decoration-none text-white">
            <mat-icon class="mat-18 item_w_icon" fontIcon="notifications_none"></mat-icon> <div matBadge="{{alertsCount}}" [matBadgeHidden]="alertsCount === 0" matBadgeSize="large" class="demo-section">Notifications</div>
        </a>
    </mat-list-item>

    <mat-list-item class="item_w_icon_content">
        <a [routerLink]="['/email']" class="text-decoration-none text-white">
            <mat-icon class="mat-18 item_w_icon"  aria-hidden="false" fontIcon="mail_outline"></mat-icon> E-mail
        </a>
    </mat-list-item>

</mat-list>

