import { MatCardModule } from '@angular/material/card';
import { ChangeDetectionStrategy, Component,Inject,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ActivatedRoute} from '@angular/router';
import { AddSensorComponent } from '../../components/add-sensor/add-sensor.component';
import { SensorService } from '../../services/sensor/sensor.service';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-sensors',
  standalone: true,
  imports: [NgxSkeletonLoaderModule,MatProgressSpinnerModule, CommonModule, MatGridList,MatGridTile,MatCardModule,MatButtonModule,MatIcon],
  templateUrl: './sensors.component.html',
  styleUrl: './sensors.component.scss'
})
export class SensorsComponent {

  cardId:any = null;
  zoneId:any = null;
  zoneName:string | null = "";
  cardName:string | null = "";
  sensors:any = null;
  breakpoint:number = 4;
  refresh:boolean = false;
  
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

constructor(private route: ActivatedRoute,private sensorservice: SensorService) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.cardId = params.get('card_id'); 
      this.zoneId = params.get('zone_id'); 
      this.zoneName = params.get('zone_name'); 
      this.cardName = params.get('card_name'); 
      setTimeout(() => {
        this.getSensors();
      }, 1000);

      setInterval(() => {
        this.getSensors();
      }, 10000);
    });
  }


  openCreateDialog(): void {
    this.dialog.open(AddSensorComponent, {
      width: '75vw',
      data : {cardId:this.cardId,zoneId:this.zoneId}
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getSensors();
      }
    });
  }

  openDeleteDialog(sensor_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteSensor, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { sensor_id: sensor_id }
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getSensors();
      }
    });
  }

  getSensors(){
    this.refresh = true;
    this.sensorservice.getAllSensors(this.cardId).subscribe(
      (response) => {
        if (response) {
          this.sensors = response;
          setTimeout(() => {   this.refresh = false; }, 1000);
        }
      }
    );

   
    
  }

}



@Component({
  selector: 'delete-sensor-dialog',
  templateUrl: '../../templates/delete-sensor-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteSensor {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteSensor>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { sensor_id: number }
  ,private sensorService:SensorService,private _snackBar: MatSnackBar){

  }

  deleteSensor(sensor_id:number){
    this.sensorService.deleteSensor(sensor_id).subscribe(
      (response) => {
        if(response && response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true); 
        }else{
          this.openSnackBar("Error Server Try again","close")
          this.dialogRef.close(true); 
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}