<h1 mat-dialog-title  *ngIf="iscreate " >Add new Sensor</h1>
<h1  mat-dialog-title *ngIf="!iscreate "  >Update Sensor {{form.get('name')?.value}}</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Name </mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Sensor Type </mat-label>
      <mat-select formControlName="sensor_type_id">
        <mat-option  [value]="item.id" *ngFor="let item of sensorTypes">{{item.name+" - "+item.unit}}</mat-option>
      </mat-select>
    </mat-form-field>
    

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Lat </mat-label>
      <input matInput formControlName="location_lat">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Lng </mat-label>
      <input matInput formControlName="location_lng">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Ex. cover 10 meters" formControlName="description"></textarea>
    </mat-form-field>

   


  </form>

  <form [formGroup]="formcameras" *ngIf="cameras?.length > 0">
    <div formArrayName="camera_ids">
      <section class="example-section">
        <h4>Select Related Camera:</h4>
        <div class="camera-grid">
          <mat-checkbox *ngFor="let camera of cameras; let i = index" [formControlName]="i">
            {{ camera.name }}
          </mat-checkbox>
        </div>
      </section>
    </div>
  </form>
  

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>