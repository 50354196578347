<div class="container mt-3 mb-5">
  
  <div class=" row mb-3">
    <h4 class="col-6  text-start">Projects List</h4>
    <div class="col-6 text-end   d-block ">
      <a mat-fab extended class="h-auto py-2" (click)="openCreateDialog()">
        <mat-icon class="mat-18 fs-4 align-middle">add</mat-icon>
        Ajouter
      </a>
    </div>
  </div>

  <mat-grid-list *ngIf="projects; else shimmer" [cols]="breakpoint" [rowHeight]="'335px'" [gutterSize]="'15px'" class="mx-2 text-white"
    (window:resize)="onResize($event)">
    <mat-grid-tile [colspan]="1" [rowspan]="1" *ngFor="let project of projects">
      <mat-card class=" position-relative" > 
          <img matCardImage src="../../../assets/images/project.jpg" >
        <mat-card-content class="my-2">
          <b><a [routerLink]="['/zones/',project.id,project.name]" routerLinkActive="router-link-active"  class="text-decoration-none text-white">{{project.name}}</a></b>
        </mat-card-content>
        <mat-card-footer class=" text-center d-block position-absolute bottom-0 w-100 m-auto">
          <a mat-flat-button color="secondary" class="w-75 mb-3">{{project.status}}</a>
          <div  class=" m-auto w-100 mb-2">
            <mat-chip class="py-3 mx-1"><mat-icon class="mat-18 fs-5 align-middle">filter_none</mat-icon> {{project.zonesCount}}</mat-chip>
            <mat-chip class="py-3 mx-1"><mat-icon class="mat-18 fs-5 align-middle">settings_input_component</mat-icon> {{project.cardsCount}}</mat-chip>
            <mat-chip class="py-3 mx-1"><mat-icon class="mat-18 fs-5 align-middle">camera_outdoor</mat-icon> {{project.camerasCount }}</mat-chip>
            <mat-chip class="py-3 mx-1"><mat-icon class="mat-18 fs-5 align-middle">sensors</mat-icon> {{project.sensorsCount}}</mat-chip>
          </div>
        </mat-card-footer>
        <button  mat-icon-button (click)="openUpdateDialog(project)"   class="text-warning w-content top-0 start-0 position-absolute h-0 px-0 py-1 "><mat-icon class="mat-18 ">edit</mat-icon></button>
        <button mat-icon-button (click)="openDeleteDialog(project.id,'400ms','200ms')" class=" text-danger w-content top-0 end-0 position-absolute h-0 px-0 py-1 "><mat-icon class="mat-18 ">delete_outline</mat-icon></button>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

  <ng-template #shimmer>
    <mat-grid-list [cols]="breakpoint" [rowHeight]="'335px'" [gutterSize]="'15px'" class="mx-2 text-white">
      <mat-grid-tile [colspan]="1" [rowspan]="1" *ngFor="let n of [1,2,3,4,5,6,7,8]">
        <mat-card>
          <ngx-skeleton-loader count="1" appearance="custom-content" class="h-75"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" appearance="custom-content" class="mt-3"></ngx-skeleton-loader>
          <div class="row my-2 mx-auto w-100 text-center">
            <div class="col-4 "><ngx-skeleton-loader count="1" appearance="custom-content" class="w-100 m-auto "></ngx-skeleton-loader></div>
            <div class="col-4 "><ngx-skeleton-loader count="1" appearance="custom-content" class="w-100 m-auto "></ngx-skeleton-loader></div>
            <div class="col-4 " ><ngx-skeleton-loader count="1" appearance="custom-content" class="w-100 m-auto "></ngx-skeleton-loader></div>
          </div>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </ng-template>

</div>