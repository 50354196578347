import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Zone } from '../../types/Zone';
import { ZonesService } from '../../services/zone/zones.service';

@Component({
  selector: 'app-add-zone',
  standalone: true,
  imports: [    
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule],
  templateUrl: './add-zone.component.html',
  styleUrl: './add-zone.component.scss'
})
export class AddzoneComponent {
 
  form: FormGroup;
  iscreate:boolean = true;

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private _snackBar: MatSnackBar,
    private zoneservice:ZonesService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddzoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data:   Zone 
  ) {
    this.form = this.formBuilder.group({
      id: ['', Validators.nullValidator],
      name: ['', Validators.required],
      hasAlerts: [true, Validators.required],
      // hash: ['abc123', Validators.required],
      // insertedAt: ['2023-06-06T12:00:00Z', Validators.required],
      // startedAt: ['2023-06-06T12:00:00Z', Validators.required],
      projectId: ['', Validators.required],
      camerasCount: [0, Validators.required],
      sensorsCount: [0, Validators.required],
      sensors: [[], Validators.nullValidator],
      cameras: [[], Validators.nullValidator]
    });

  }

  ngOnInit(): void {

    if (this.data.name) {
      this.form.patchValue(this.data);
      this.iscreate = false;
    }else{
      this.form.patchValue({'projectId':this.data.projectId});
    }
    
  }
  
  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        this.zoneservice.addZone(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    } else {
      if (this.form.valid) {
        this.zoneservice.updateZone(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    }
   
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
