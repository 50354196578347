import { Component,ChangeDetectionStrategy,Inject,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../pipes/safe.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet, MatChipsModule } from '@angular/material/chips';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { RecordingService } from '../../services/recording/recording.service';
import { CreateRecordingComponent } from '../../components/create-recording/create-recording.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCell, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';

export interface VideoRecording {
  dateFrom: Date;
  timeFrom: string;
  dateTo: Date;
  timeTo: string;
  duration: string;
  description: string;
  camRef: string;
  project: string;
  zone: string;
  createdAt: Date;
  url: string;
}

const VIDEO_RECORDINGS: VideoRecording[] = [
  {
    dateFrom: new Date('2024-08-01'),
    timeFrom: '10:00:00',
    dateTo: new Date('2024-08-01'),
    timeTo: '11:00:00',
    duration: '1h',
    description: 'Meeting with team',
    camRef: 'Cam001',
    project: 'Project Alpha',
    zone: 'Conference Room',
    createdAt: new Date('2024-08-01T11:05:00'),
    url: 'https://example.com/videos/meeting_with_team.mp4',
  },
  {
    dateFrom: new Date('2024-08-02'),
    timeFrom: '14:00:00',
    dateTo: new Date('2024-08-02'),
    timeTo: '15:30:00',
    duration: '1h 30m',
    description: 'Security check',
    camRef: 'Cam002',
    project: 'Project Beta',
    zone: 'Entrance',
    createdAt: new Date('2024-08-02T15:35:00'),
    url: 'https://example.com/videos/security_check.mp4',
  },
  // Add more video recordings as needed
];



@Component({
  selector: 'app-recordings',
  standalone: true,
  imports: [
    MatHeaderCell, MatRow,MatRowDef,MatIconModule, MatHeaderCellDef,MatHeaderRow,MatHeaderRowDef,MatCell,MatPaginatorModule, RouterLink,MatChip,MatTableModule
    ,MatChipsModule, MatChipSet, CommonModule, NgxSkeletonLoaderModule,
     MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,
     MatGridList,MatGridTile,MatButtonModule,SafePipe,MatMenuModule],
  templateUrl: './recordings.component.html',
  styleUrl: './recordings.component.scss'
})
export class RecordingsComponent {
  displayedColumns: string[] = [
    'dateFrom', 'timeFrom', 'dateTo', 'timeTo', 'duration', 'description', 'camRef', 'project', 'zone', 'createdAt', 'url', 'Action'
  ];
  dataSource :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private recordingservice:RecordingService,private _snackBar: MatSnackBar){

  }
  ngOnInit() {
    setTimeout(() => {
      this.getRecordings();
    }, 500);
    this.dataSource = new MatTableDataSource(VIDEO_RECORDINGS);
    
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getRecordings() {
    
    // this.recordingservice.getAllRecordings().subscribe(
    //   (response) => {

    //     if (response.data) {
    //       this.recordings = response.data[0];
    //     }else{
    //       this.recordings = [];
    //     }
    //   }
    // );
  }
  
  openCreateDialog(): void {
     this.dialog.open(CreateRecordingComponent, {
      width: '75vw',
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getRecordings();
      }
    });
  }


  openDeleteDialog(recording_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteRecording, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { recording_id: recording_id }
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getRecordings();
      }
    });

  }
}

@Component({
  selector: 'delete-recording-dialog',
  templateUrl: '../../templates/delete-recording-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteRecording {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteRecording>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { recording_id: number }
  ,private recordingservice:RecordingService,private _snackBar: MatSnackBar){

  }

  deleteRecording(recording_id:number){
    this.recordingservice.deleteRecording(recording_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true); 
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
