<h1 mat-dialog-title  *ngIf="iscreate " >Add new Camera</h1>
<h1  mat-dialog-title *ngIf="!iscreate "  >Update Camera {{form.get('name')?.value}}</h1>

<div mat-dialog-content class="w-100">
  <form [formGroup]="form">

    <mat-form-field appearance="fill" class="w-100" *ngIf="iscreate && !hasZone">
      <mat-label>Project</mat-label>
      <mat-select  (selectionChange)="getZones($event)"> 
        <mat-option  [value]="project.id" *ngFor="let project of projects">{{project.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100" *ngIf="iscreate && !hasZone">
      <mat-label>Zone</mat-label>
      <mat-select formControlName="zoneId" [disabled]="!zones"> 
        <mat-option  [value]="zone.id" *ngFor="let zone of zones">{{zone.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
        <mat-label>URL</mat-label>
        <input matInput formControlName="thumbnailUrl">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
        <mat-label>LAT</mat-label>
        <input matInput formControlName="location_lat">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
        <mat-label>LNG</mat-label>
        <input matInput formControlName="location_lng">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Model</mat-label>
        <input matInput formControlName="model">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status"> 
        <mat-option  [value]="item" *ngFor="let item of cameraStatus">{{item}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Ex. cover 10 meters" formControlName="description"></textarea>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>