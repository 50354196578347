<h1 mat-dialog-title  *ngIf="iscreate " >Add new Card</h1>
<h1  mat-dialog-title *ngIf="!iscreate "  >Update Card {{form.get('name')?.value}}</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <!-- <mat-form-field appearance="fill" class="w-100">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field> -->

    <!-- <mat-form-field appearance="fill" class="w-100">
      <mat-label>Model</mat-label>
      <mat-select formControlName="model">
        <mat-option  [value]="item" *ngFor="let item of sensorTypes">{{item}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Ex. cover 10 meters" formControlName="description"></textarea>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>