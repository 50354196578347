<div class="background-container"   fxLayout="row" >
    <img fxFlex="50" class="background-image" src="../../assets/images/camera.jpg">
    <div fxFlex="50" class="overlay">
      <form [formGroup]="LoginFrom" class="login-form container">
        <h2>Sign In</h2>
        <mat-form-field appearance="outline" class="mb-2 w-100" >
          <mat-label>E-mail</mat-label>
          <input matInput formControlName="email">
          <mat-error *ngIf="LoginFrom.get('email')?.hasError('required')">Required Field</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mb-2 w-100">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password">
          <mat-error *ngIf="LoginFrom.get('password')?.hasError('required')">Required Field</mat-error>
        </mat-form-field>
        <button [disabled]="submitted" mat-button (click)="login()" class="login-button w-100">
          <span class="button-content">
            <mat-spinner *ngIf="submitted" [diameter]="25"></mat-spinner>
            <span *ngIf="!submitted">Login</span>
          </span>
        </button>
      </form>
    </div>
  </div>
  