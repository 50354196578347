
<div class="container mt-4">
 

 

      
  <div class="row mb-3">
    <h4 class="col-6 text-start">Recordings List</h4>
    <div class="col-6 text-end d-block">
      <a mat-fab extended class="h-auto py-2" (click)="openCreateDialog()">
        <mat-icon class="mat-18 fs-4 align-middle">add</mat-icon>
        Ajouter
      </a>
    </div>
  </div>
  
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- DateFrom Column -->
    <ng-container matColumnDef="dateFrom">
      <th mat-header-cell *matHeaderCellDef> Date From </th>
      <td mat-cell *matCellDef="let element"> {{element.dateFrom | date:'shortDate'}} </td>
    </ng-container>

    <!-- TimeFrom Column -->
    <ng-container matColumnDef="timeFrom">
      <th mat-header-cell *matHeaderCellDef> Time From </th>
      <td mat-cell *matCellDef="let element"> {{element.timeFrom}} </td>
    </ng-container>

    <!-- DateTo Column -->
    <ng-container matColumnDef="dateTo">
      <th mat-header-cell *matHeaderCellDef> Date To </th>
      <td mat-cell *matCellDef="let element"> {{element.dateTo | date:'shortDate'}} </td>
    </ng-container>

    <!-- TimeTo Column -->
    <ng-container matColumnDef="timeTo">
      <th mat-header-cell *matHeaderCellDef> Time To </th>
      <td mat-cell *matCellDef="let element"> {{element.timeTo}} </td>
    </ng-container>

    <!-- Duration Column -->
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef> Duration </th>
      <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <!-- CamRef Column -->
    <ng-container matColumnDef="camRef">
      <th mat-header-cell *matHeaderCellDef> Camera Ref </th>
      <td mat-cell *matCellDef="let element"> {{element.camRef}} </td>
    </ng-container>

    <!-- Project Column -->
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef> Project </th>
      <td mat-cell *matCellDef="let element"> {{element.project}} </td>
    </ng-container>

    <!-- Zone Column -->
    <ng-container matColumnDef="zone">
      <th mat-header-cell *matHeaderCellDef> Zone </th>
      <td mat-cell *matCellDef="let element"> {{element.zone}} </td>
    </ng-container>

    <!-- CreatedAt Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef> Created At </th>
      <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'short'}} </td>
    </ng-container>

    <!-- URL Column -->
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef> URL </th>
      <td mat-cell *matCellDef="let element">
        <a mat-raised-button href="{{element.url}}" target="_blank"><mat-icon>play_circle_outline</mat-icon> Watch</a>
      </td>
    </ng-container>
    
     <!-- CamRef Column -->
     <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item >
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>Download</mat-icon>
            <span>Download</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Table Rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of video recordings">
  </mat-paginator>
</div>