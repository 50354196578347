import { ApplicationModule, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { CommonModule } from '@angular/common';
import { SidemenuComponent } from './shared/sidemenu/sidemenu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatDivider } from '@angular/material/divider';
import { MatBadge, MatBadgeModule } from '@angular/material/badge';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { Alerts } from './types/Alerts';
import { AlertsService } from './services/alerts/alerts.service';
import { CameraStatus } from './types/Camera';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { ZonesService } from './services/zone/zones.service';
import { ProjectService } from './services/project/project.service';
import { AuthService } from './services/auth/auth-service.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SidemenuService } from './services/sidemenu/sidemenu.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidemenuComponent, ApplicationModule, CommonModule, MatIconModule, MatMiniFabButton, MatMenu, MatMenuTrigger, MatIconButton, MatMenuItem, MatDivider, MatBadgeModule, MatBadge],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  title = 'camapp_w';
  dataMenu: any = null;
  isConnected = false;
  notificationCount = 0;
  active = true;
  private notificationSubscription!: Subscription;

  constructor(private notificationsService: NotificationsService, private sideMenuservice: SidemenuService, private responsive: BreakpointObserver, private authService: AuthService, private zoneService: ZonesService, private projectService: ProjectService, private dialog: MatDialog, private alertsService: AlertsService) {
  }


  ngOnInit(): void {

    this.sideMenuservice.menu$.subscribe(
      (active: boolean) => {
        this.active = active;
      }
    )

    this.responsive.observe([
      Breakpoints.HandsetPortrait
    ])
      .subscribe(result => {

        this.active = true;

        if (result.matches) {
          this.active = false;
        }
      });

    this.authService.isAuthenticated$.subscribe(
      (isAuthenticated: boolean) => {
        this.isConnected = isAuthenticated;
        if (this.isConnected) {
          this.getMenuItems();
        }
      }
    );

    setTimeout(() => {
      this.alertsService.checkForAlerts().subscribe(
        (notifications: any) => {
          if (notifications && notifications?.length) {
            this.dialog.open(AlertsDialogComponent, {
              data: notifications
            });
          }

        })

    });

    // this.dialog.open(AlertsDialogComponent, {
    //   data: Alerts_on
    // });
    // }, 10000);

     // Fetch notifications every 5 seconds
    //  this.notificationSubscription = interval(15000).subscribe(() => {
    //   this.notificationsService.getNotifications().subscribe();
    // });

    // this.notificationsService.notifications$.subscribe(notification => {
    //   // Handle notification display
    //   console.log(notification);

    //     if (notification && notification?.length) {
    //       this.dialog.open(AlertsDialogComponent, {
    //         data: notification
    //       });
    //     }
    // });

    // Subscribe to notifications
    
  }


  // ngOnDestroy(): void {
  //   if (this.notificationSubscription) {
  //     this.notificationSubscription.unsubscribe();
  //   }
  // }

  getMenuItems() {
    this.projectService.getAllProjects().subscribe(
      (response) => {
        this.dataMenu = response;
        setTimeout(() => {

          this.dataMenu.forEach((element: { id: any; zones: any; }) => {

            this.zoneService.getAllZones(element.id).subscribe(

              (rsp) => { element.zones = rsp; }
            )

          });
        }, 100);
      }
    );

  }

}

@Component({
  selector: 'alerts-dialog',
  templateUrl: 'templates/alerts-dialog.html',
  styleUrl: 'templates/style/alerts-dialog.scss',
  standalone: true,
  imports: [MatCardModule, MatListModule, MatButtonModule, MatIconModule, CommonModule, MatButtonModule, MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AlertsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) { }

  close(): void {
    this.dialogRef.close();
  }

  opencamera(url: any) {
    window.open(url, '_blank');
  }

}