import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RecordingService } from '../../services/recording/recording.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Recording } from '../../types/Recording';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {  MatFormFieldModule  } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {  MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import {NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';

@Component({
  selector: 'app-create-recording',
  standalone: true,
  providers: [provideNativeDateAdapter(),{provide: MAT_DATE_LOCALE, useValue: 'fr-FR'}],
  imports: [ ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDatepickerToggle, 
    NgxMaterialTimepickerModule,
    ],
    templateUrl: './create-recording.component.html',
    styleUrl: './create-recording.component.scss'
})
export class CreateRecordingComponent {

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#2f2928',
        buttonColor: '#ffb4a8'
    },
    dial: {
        dialBackgroundColor: '#555',
    },
    clockFace: {
        clockFaceBackgroundColor: '#555',
        clockHandColor: '#ffb4a8',
        clockFaceTimeInactiveColor: '#fff'
    }
};

  form: FormGroup;
  iscreate:boolean = true;

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private _snackBar: MatSnackBar,
    private recordinservice:RecordingService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateRecordingComponent>,
    @Inject(MAT_DIALOG_DATA) public data:   Recording 
  ) {
    this.form = this.formBuilder.group({
      id_project: ['', Validators.nullValidator],
      id_zone: ['', Validators.nullValidator],
      id_camera: ['', Validators.nullValidator],
      from: ['' , Validators.required],
      to: ['', Validators.required],
      date: ['', Validators.required],
      description: ['', Validators.nullValidator],
      createdAt: ['2024-03-02', Validators.required]
    });

  }

  ngOnInit(): void {
    
  }
  
  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        this.recordinservice.createRecording(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    } 
   
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


}
