import { Component,ChangeDetectionStrategy,Inject,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../pipes/safe.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet, MatChipsModule } from '@angular/material/chips';
import {  MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCell, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { AlertsService } from '../../services/alerts/alerts.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    MatHeaderCell, MatRow,MatRowDef,MatIconModule, MatHeaderCellDef,MatHeaderRow,MatHeaderRowDef,MatCell,MatPaginatorModule, RouterLink,MatChip,MatTableModule
    ,MatChipsModule, MatChipSet, CommonModule, NgxSkeletonLoaderModule,
     MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,
     MatGridList,MatGridTile,MatButtonModule,SafePipe,MatMenuModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {


  displayedColumns: string[] = [
    'ID', 'Message', 'Type', 'Value', 'Threshold',  'State',  'CreatedAt', 'Action' 
  ]; //'Cameras','Capture',
  dataSource :any
  sensorTypes :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  skip:number = 0
  limit:number = 15

  constructor(private alertsService:AlertsService,private _snackBar: MatSnackBar){

  }
  ngOnInit() {
    setTimeout(() => {
      this.getNotifications();
    }, 500);
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  getNotifications(){

    this.alertsService.getAllAlerts(this.skip,this.limit).subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
          this.dataSource = new MatTableDataSource(response);
        }
      }
    );
  }


  openDeleteDialog(alert_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDesactivateNotification, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { alert_id: alert_id }
    }).afterClosed().subscribe((result) => {
      if(result){
        this.getNotifications();
      }
    });

  }
}

@Component({
  selector: 'desactivate-alert-dialog',
  templateUrl: '../../templates/desactivate-alert-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDesactivateNotification {
  readonly dialogRef = inject(MatDialogRef<DialogDesactivateNotification>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { alert_id: number }
  ,private alertService:AlertsService,private _snackBar: MatSnackBar){

  }

  deleteSensorType(alert_id:number){
    this.alertService.desactivate(alert_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true); 
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
