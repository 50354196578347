<div class="container">
  
  <div class=" row mt-4">
    <h4 class="col-6  text-start">Sensors List of {{cardName}} of {{zoneName}} </h4>
    <div class="col-6 text-end   d-block ">
      <a mat-fab extended class="h-auto py-2" (click)="openCreateDialog()">
        <mat-icon class="mat-18 fs-4 align-middle">add</mat-icon>
        Ajouter
      </a>
    </div>
  </div>
  
  <ng-container *ngIf="sensors; else shimmer" >

    <mat-grid-list cols="4" rowHeight="3:1" class="mt-5" [gutterSize]="'4vh'" *ngIf="sensors.length > 0; else empty">
      
      <mat-grid-tile [colspan]="1" [rowspan]="1" *ngFor="let sensor of sensors">
        <mat-card class="h-100 w-100 m-auto text-center">
          <mat-card-content>Sensor of {{sensor.name}} </mat-card-content>
          <mat-card-footer class="m-auto text-center">
            <span *ngIf="!refresh">{{sensor?.latest_sensor_data?.value}} {{sensor?.unit}}</span>
            <mat-spinner [diameter]="20" *ngIf="refresh"></mat-spinner>
          </mat-card-footer>
          <button mat-icon-button (click)="openDeleteDialog(sensor.id,'400ms','200ms')" class=" text-danger w-content top-0 end-0 position-absolute h-0 px-0 py-1 "><mat-icon class="mat-18 ">delete_outline</mat-icon></button>
        </mat-card>
      </mat-grid-tile>
    
    </mat-grid-list>
 </ng-container>

   
  <ng-template #empty>
    <mat-card class=" w-100 d-block text-center m-auto mt-5">
        <mat-icon class="mat-24  empty_icon" >cloud_off</mat-icon>
        <mat-card-content>
            There is no Sensors in selected card
        </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #shimmer>

    <mat-grid-list cols="4" rowHeight="3:1" class="mt-5" [gutterSize]="'4vh'">
      
      <mat-grid-tile [colspan]="1" [rowspan]="1" *ngFor="let n of [1,2,3,4,5,6,7,8,1,2,3,4,5,6,7,8]">
          <ngx-skeleton-loader count="1" appearance="custom-content" class="h-100 w-100 m-auto text-center"></ngx-skeleton-loader>
      </mat-grid-tile>
    
    </mat-grid-list>

  </ng-template>
</div>

