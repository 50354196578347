<div class="mx-5 ">
    <div class="row">
      <div class="col-12">
        <div class="filter-container">
          <mat-button-toggle-group [(ngModel)]="currentFilter" (change)="updateCharts()" aria-label="Time Filter">
            <mat-button-toggle value="lasthour">Last Hour</mat-button-toggle>
            <mat-button-toggle value="hourly">Hourly</mat-button-toggle>
            <mat-button-toggle value="daily">Daily</mat-button-toggle>
            <mat-button-toggle value="weekly">Weekly</mat-button-toggle>
            <mat-button-toggle value="monthly">Monthly</mat-button-toggle>
            <mat-button-toggle value="yearly">Yearly</mat-button-toggle>
            <mat-button-toggle value="all">All</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <ng-container *ngIf="isActive;else shimmer">
        
        <div class="col-4 my-3" *ngFor="let chartOptions of chartOptionsList; let i = index">
          <highcharts-chart 
            [Highcharts]="Highcharts"
            [options]="chartOptions"
            style="width: 100%; height: 270px; display: block;">
          </highcharts-chart>
        </div>

      </ng-container>

          
      <ng-template #shimmer>
        <div class="row" >
        <div class="col-6 my-3" *ngFor="let n of [1,2,3,4,5,6,7,8]">
          <mat-card >
            <mat-card-content class="m-0 p-0 w-100 h-100">
              <ngx-skeleton-loader count="1" appearance="custom-content" class="w-100 m-auto " style="width: 100%; height: 400px; display: block;" ></ngx-skeleton-loader>
            </mat-card-content>
          </mat-card>
        </div>
        </div>
    </ng-template>
    
    </div>
  </div>
  