<div class="container row m-auto ">

  <div class="col-12 mb-4">
    <ng-container *ngIf="carouselTileItems; else shimmerSensors" >

      <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselTileItems" *ngIf="carouselTileItems.length > 0; else emptySensors">
        <ngu-tile *nguCarouselDef="let sensor of carouselTileItems">
            
          <mat-card class="h-100 w-100 m-auto text-center pb-2">
            <mat-card-content>{{sensor.name}} </mat-card-content>
            <mat-card-footer class="m-auto text-center">
              <span *ngIf="!refresh">{{sensor?.latest_sensor_data?.value}} {{sensor?.unit}}</span>
              <mat-spinner [diameter]="25" *ngIf="refresh"></mat-spinner>
            </mat-card-footer>
          </mat-card>
      
        </ngu-tile>
  
      </ngu-carousel>
  </ng-container>
     

  </div>
  
  <div #videoContainer class="video-container position-relative col-9 ml-0 mb-3 p-0" style="overflow: hidden;">
    <img #videoElement
         alt="MJPEG Stream"
         class="image-content position-absolute"
         [style.display]="videoPresent ? 'block' : 'none'"
         (load)="onImageLoad()"
         (error)="onImageError()" />
  
    <div class="image-content spinner-container" *ngIf="!videoPresent">
      <mat-spinner [diameter]="125"></mat-spinner>
    </div>
  </div>
  
  


    <div class="col-3 mt-5">
      <div class="row m-auto text-center"  *ngIf="cameras;else shimmer">
        <ng-container *ngIf="cameras.length > 0 ;else empty">

          <div class="col-12 mt-2">
            <button (click)="openCreateDialog()"    class="w-100" mat-raised-button  color="secondary">
              <mat-icon>add</mat-icon>
              Add New Camera
            </button>
          </div>

        <div class="col-12 mt-2">
          <button [routerLink]="['/cameraswatch',zoneId,zoneName]" routerLinkActive="router-link-active"  class="w-100" mat-raised-button  color="secondary">
            <mat-icon>monitor</mat-icon>
           Watch All Cameras
          </button>
        </div>

          <div class="col-6 mt-3" *ngFor="let camera of cameras">
            <button mat-raised-button (click)="startCamera(camera)" color="primary">
              <mat-icon>camera</mat-icon>
              {{camera.name}}
            </button>
          </div>

        </ng-container>
     
      </div>
    </div>
  
    <div class="col-9  ">
      <div class="controls-container bottom-0 bg-dark text-white p-2 w-100">
        <div class="d-flex start-0">

        <button mat-raised-button (click)="togglePlayPause()" color="primary">
          <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
          {{ isPlaying ? 'Pause' : 'Play' }}
        </button>
    
        <button mat-raised-button (click)="toggleFullScreen()" color="primary">
          <mat-icon>fullscreen</mat-icon>
          Full 
        </button>
    
        <button mat-raised-button (click)="captureScreenshot()" color="primary" *ngIf="!takingScreen">
          <mat-icon>camera_alt</mat-icon>
          Shot
        </button>
        <button mat-raised-button color="primary" *ngIf="takingScreen">
          <mat-spinner *ngIf="takingScreen" [diameter]="25"></mat-spinner>
        </button>
    
        <button mat-raised-button (click)="zoomIn()" color="primary">
          <mat-icon>zoom_in</mat-icon>
          Z-In
        </button>
        <button mat-raised-button (click)="zoomOut()" color="primary">
          <mat-icon>zoom_out</mat-icon>
          Z-Out
        </button>
        <button mat-raised-button (click)="zoomInit()" color="primary">
          <mat-icon>zoom_out</mat-icon>
          Z-Init
        </button>
      </div>
      <div class="d-flex end-0 " *ngIf="selectedCamera">
      
        <button mat-raised-button  (click)="openDeleteDialog(selectedCamera.id,'400ms','200ms')" color="primary" >
          <mat-icon>delete</mat-icon>
          DELETE
        </button>
        <button mat-raised-button mat-accent (click)="openUpdateDialog()" color="accent">
          <mat-icon>edit</mat-icon>
          UPDATE
        </button>
    
      </div>
    
      </div>
      
    </div>
  
       
    <ng-template #empty>
      <mat-card class=" w-100 d-block text-center m-auto mt-5">
          <mat-icon class="mat-24  empty_icon" >cloud_off</mat-icon>
          <mat-card-content>
              There is no Cameras in selected zone
          </mat-card-content>
      </mat-card>
    </ng-template>

    <ng-template #shimmer>
      <div class="row m-auto text-center">
        <div class="col-12 mt-2 px-3">
          <ngx-skeleton-loader count="1"  appearance="custom-content" [theme]="{ 'height': '40px', 'width': '100%' }"></ngx-skeleton-loader>
        </div>
    
        <div class="col-6 mt-2 px-3" *ngFor="let i of [1,2,3,4,5,6,7,8,1,2,3,4,5,6,7,8]">
          <ngx-skeleton-loader count="1"  appearance="custom-content" [theme]="{ 'height': '40px', 'width': '100%' }"></ngx-skeleton-loader>
        </div>
      </div>
    </ng-template>


    <ng-template #emptySensors>
      <mat-card class=" w-100 d-block text-center m-auto my-4">
          <mat-icon class="mat-24  empty_icon mt-3" >cloud_off</mat-icon>
          <mat-card-content>
              There is no Sensors in this zone
          </mat-card-content>
      </mat-card>
    </ng-template>
  
    <ng-template #shimmerSensors>
  
      <mat-grid-list cols="6" rowHeight="3:1" class="my-4" [gutterSize]="'4vh'">
        
        <mat-grid-tile [colspan]="1" [rowspan]="1" *ngFor="let n of [1,2,3,4,5,6]">
            <ngx-skeleton-loader count="1" appearance="custom-content" class="h-100 w-100 m-auto text-center"></ngx-skeleton-loader>
        </mat-grid-tile>
      
      </mat-grid-list>
  
    </ng-template>

  </div>