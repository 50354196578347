import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Camera, CameraStatus } from '../../types/Camera';
import { CameraService } from '../../services/camera/camera.service';
import { ProjectService } from '../../services/project/project.service';
import { ZonesService } from '../../services/zone/zones.service';

@Component({
  selector: 'app-add-camera',
  standalone: true,
  imports: [    
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule],
  templateUrl: './add-camera.component.html',
  styleUrl: './add-camera.component.scss'
})
export class AddCameraComponent {

  form: FormGroup;
  iscreate:boolean = true;
  cameraStatus: string[];
  projects:any = null;
  zones:any=null;
  projectId:any=null;
  hasZone:boolean = false;

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private projectService:ProjectService,
    private zoneService:ZonesService,
    private _snackBar: MatSnackBar,
    private cameraservice:CameraService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddCameraComponent>,
    @Inject(MAT_DIALOG_DATA) public data:   Camera 
  ) {

    this.cameraStatus = Object.values(CameraStatus);

    this.form = this.formBuilder.group({
      id: ['', Validators.nullValidator],
      name: ['', Validators.required],
      lastOnlineAt: [null, Validators.nullValidator],
      startedAt: [null, Validators.nullValidator],
      zoneId: ['', Validators.required],
      model: ['', Validators.required],
      location_lng: ['', Validators.nullValidator],
      location_lat: ['', Validators.nullValidator],
      adjustableCoverage: [false, Validators.nullValidator],
      description: ['', Validators.nullValidator],
      isOnline: [true, Validators.nullValidator],
      modelName: ['', Validators.nullValidator],
      status: ['', Validators.required],
      thumbnailUrl: ['', Validators.required],
    });

  }

  ngOnInit(): void {

    if (this.data && this.data?.['selected_zone']) {
      this.form.patchValue({
        zoneId: this.data?.['selected_zone']
      });
      this.hasZone = true;
    }else{
      if (this.data) {
        this.form.patchValue(this.data);
        this.iscreate = false;
      }else{
        this.getProjects();
      }
    }
 
    
  }
  
  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        this.cameraservice.addCamera(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    } else {
      if (this.form.valid) {
        this.cameraservice.updateCamera(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    }
   
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  getProjects() {
    
    this.projectService.getAllProjects().subscribe(
      (response) => {
      this.projects = response;
      }
    );
  }
  
  getZones($event:MatSelectChange) {
    
    this.zoneService.getAllZones($event.value).subscribe(
      (response) => {
        if (response) {
          this.zones = response;
        }else{
          this.zones = [];
        }
      }
    );
  }
}
