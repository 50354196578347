
<div class="container mt-4">
 
    <div class="row mb-3">
      <h4 class="col-6 text-start">Sensor Types List</h4>
      <div class="col-6 text-end d-block">
        <a mat-fab extended class="h-auto py-2" (click)="openCreateDialog()">
          <mat-icon class="mat-18 fs-4 align-middle">add</mat-icon>   
          Ajouter
        </a>
      </div>
    </div>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name }} </td>
      </ng-container>

      <ng-container matColumnDef="Unit">
        <th mat-header-cell *matHeaderCellDef> Unit </th>
        <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element!.Type }} </td>
      </ng-container>
  
      <ng-container matColumnDef="MinValue">
        <th mat-header-cell *matHeaderCellDef> MinValue </th>
        <td mat-cell *matCellDef="let element"> {{element!.min_value}} </td>
      </ng-container>

      <ng-container matColumnDef="MaxValue">
        <th mat-header-cell *matHeaderCellDef> MaxValue </th>
        <td mat-cell *matCellDef="let element"> {{element!.max_value}} </td>
      </ng-container>
  
      <ng-container matColumnDef="AlertValue">
        <th mat-header-cell *matHeaderCellDef> AlertValue </th>
        <td mat-cell *matCellDef="let element"> {{element.alert_value}} </td>
      </ng-container>
      
      <ng-container matColumnDef="CreatedAt">
        <th mat-header-cell *matHeaderCellDef> Created At </th>
        <td mat-cell *matCellDef="let element"> {{element!.createdAt | date:'shortDate'}} </td>
      </ng-container>
  

       <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> 
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openUpdateDialog(element)">
              <mat-icon>edit</mat-icon>
              <span>Update</span>
            </button>
            <button mat-menu-item (click)="openDeleteDialog(element.id,'400ms','200ms')">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item disabled>
              <mat-icon>notifications_off</mat-icon>
              <span>Disable alerts</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Table Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of sensor types">
    </mat-paginator>
  </div>