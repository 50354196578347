import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private alertsSubject = new BehaviorSubject<any[]>([]);
  public alerts$ = this.alertsSubject.asObservable();

  private AlertsUrl = 'notifications'; 
  private GetAllUrl = 'alerts/'; 
  private DesactivateUrl = 'alerts/deactivate/'; 
  
  constructor(private http: HttpClient) {}

  test(alerts:any){
    this.alertsSubject.next(alerts)
  }
  checkForAlerts(): Observable<any[]> {
    return timer(0, 150000).pipe( 
      switchMap(() => this.http.get<any[]>(`${environment.ApiUrl+this.AlertsUrl}`).pipe(
        tap(alerts => this.alertsSubject.next(alerts)),
        catchError(() => of([]))
      ))
    );
  }

  getAllAlerts(skip:number = 0,limit:number=15): Observable<any[]> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.GetAllUrl}?skip=${skip}&limit=${limit}`,{ headers: headers });

  }

  desactivate(alert_id:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.patch<any>(`${environment.ApiUrl+this.DesactivateUrl+alert_id}`,{ headers: headers });

  }

}
