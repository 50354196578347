import { Component, ViewChild,ElementRef  } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../pipes/safe.pipe';
import Hls from 'hls.js';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CameraService } from '../../services/camera/camera.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@Component({
  selector: 'app-cameraswatch',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, CommonModule,MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,MatGridList,MatGridTile,MatButtonModule,SafePipe],
  templateUrl: './cameraswatch.component.html',
  styleUrl: './cameraswatch.component.scss'
})
export class CameraswatchComponent {


  breakpoint:number = 4;
  zoneId:any = null;
  zoneName:any = null;

  cameras:any = null;
  // [
  //   // { label: 'Camera 1', source: 'http://192.168.1.77:8888/video_feed' },
  //   // { label: 'Camera 2', source: 'http://192.168.1.77:8888/video_feed' },
  //   // { label: 'Camera 3', source: 'http://192.168.1.77:8888/video_feed' },
  //   // { label: 'Camera 4', source: 'http://192.168.1.77:8888/video_feed' },
  //   // { label: 'Camera 5', source: 'http://192.168.1.77:8888/video_feed' },
  //   // { label: 'Camera 6', source: 'http://192.168.1.77:8888/video_feed' },
  //   // { label: 'Camera 7', source: 'http://192.168.1.77:8888/video_feed' },
  //   // { label: 'Camera 7', source: 'http://192.168.1.77:8888/video_feed' }
  //   { label: 'Camera 1', source: 'http://192.168.1.77:8808/video_feed' },
  //   { label: 'Camera 2', source: 'http://192.168.1.77:8888/video_feed' },
  // //   { label: 'Camera 3', source: 'http://192.168.1.66:8000/stream?channel=3' },
  // //   { label: 'Camera 4', source: 'http://192.168.1.66:8000/stream?channel=4' },
  // //   { label: 'Camera 5', source: 'http://192.168.1.66:8000/stream?channel=5' },
  // //   { label: 'Camera 6', source: 'http://192.168.1.66:8000/stream?channel=6' },
  // //   { label: 'Camera 7', source: 'http://192.168.1.66:8000/stream?channel=7' },
  // //   { label: 'Camera 8', source: 'http://192.168.1.66:8000/stream?channel=8' }
  // ];

  
  constructor(private route: ActivatedRoute,private cameraservice:CameraService){

  }
  
  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 768) ? 1 : 4;
   
    this.route.paramMap.subscribe(params => {
      this.zoneId = params.get('zone_id'); 
      this.zoneName = params.get('zone_name'); 
      setTimeout(() => {
        this.getCameras();
      }, 5000);
    });
    
  }

  getCameras(){

    this.cameraservice.getAllCameras(this.zoneId).subscribe(
      (response) => {
        if (response) {
          this.cameras = response;
        }
      }
    );
  }
  

  onResize(event:any) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 4;
  }

}


/**import { Component, ViewChild, ElementRef, AfterViewInit, QueryList, ViewChildren, OnInit, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage, MatCardModule } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../pipes/safe.pipe';
import Hls from 'hls.js';
import { CommonModule } from '@angular/common';
import { loadPlayer } from 'rtsp-relay/browser';

@Component({
  selector: 'app-cameraswatch',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatCardContent, MatCardActions, MatCardFooter, MatCardImage, MatGridList, MatGridTile, MatButtonModule, SafePipe],
  templateUrl: './cameraswatch.component.html',
  styleUrls: ['./cameraswatch.component.scss']
})
export class CameraswatchComponent implements OnInit, OnDestroy, AfterViewInit {
  
  @ViewChildren('streaming') streaming!: QueryList<ElementRef>; 
  private players: any[] = [];

  breakpoint: number = 4;

  cameras = [
    { id: 'camera1', label: 'Camera 1' },
    { id: 'camera4', label: 'Camera 2' },
    { id: 'camera5', label: 'Camera 2' },
    { id: 'camera6', label: 'Camera 2' },
    // Add more cameras here
  ];

  constructor() {}

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 768) ? 1 : 4;
  }

  ngAfterViewInit() {
    this.initializeStreams();
  }

  initializeStreams() {
    this.streaming.forEach((stream, index) => {
      const player = loadPlayer({
        url: `ws://localhost:2000/api/stream/${this.cameras[index].id}`,
        canvas: stream.nativeElement,
        onDisconnect: () => console.log(`Connection lost for ${this.cameras[index].label}!`),
      });
      this.players.push(player);
    });
  }

  onResize(event: any) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 4;
  }

  ngOnDestroy() {
    this.players.forEach(player => {
      player.stop(); // Stop each player when component is destroyed
    });
  }
}
 */