import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private displayedNotifications = new Set<string>();
  private notificationsSubject = new Subject<any>();

  private AlertsUrl = 'notifications'; 
  
  constructor(private http: HttpClient) {}

  getNotifications(): Observable<any> {
    return this.http.get<any[]>(`${environment.ApiUrl+this.AlertsUrl}`).pipe(
      tap(notifications => {
        notifications.forEach(notification => {
          if (!this.displayedNotifications.has(notification.id)) {
            this.displayedNotifications.add(notification.id);
            this.notificationsSubject.next(notification);
          }
        });
      })
    );
  }

  get notifications$(): Observable<any> {
    return this.notificationsSubject.asObservable();
  }
}
